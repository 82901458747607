import { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { IoArrowBack } from "react-icons/io5";
import Spinner from "../spinner";

interface ComponentProps {
  onPrev: () => void;
}

const Reset = ({ onPrev }: ComponentProps) => {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [show, setShow] = useState(false);
  const [shownew, setShowNew] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleSubmit = (e: { preventDefault: () => void; }) => {
    e.preventDefault(); // Prevent form from submitting and refreshing the page
    setLoading(true);

    // Check if newPassword and confirmPassword are the same
    if (password === newPassword) {
      console.log("Passwords match. Proceed with the update.");
      setLoading(false);
      // Proceed with updating the password or other actions
    } else {
      // If they don't match, set an error message
      setErrorMessage('Passwords do not match. Please try again.');
    }
  };

  return (
    <>
      <div className="font-sans lg:px-16 md:px-16 px-12 pt-7 pb-5">
        {/* <button
          className="bg-atlas_dark-brown rounded-full p-2"
          onClick={onPrev}
        >
          <IoArrowBack size={20} />
        </button> */}
        <h3 className="text-4xl text-atlas_black dark:text-white">Reset Password</h3>
        <p className="font-normal text-opacity-70 text-sm text-atlas_gray dark:text-atlas_darkslate">
          Input your new password
        </p>
      </div>
      <form className="lg:px-16 md:px-16 px-12 font-sans" onSubmit={handleSubmit}>
        <div className="pb-5">
          <label
            htmlFor="password"
            className="text-atlas_black dark:text-white font-normal text-lg block"
          >
            New password <span className="text-red-500">*</span>
          </label>
          <div className="relative flex items-center">
            <input
              type={show ? "text" : "password"}
              name="password"
              className="px-3 py-3 rounded-md w-full text-atlas_black dark:text-white bg-atlas_silver dark:bg-atlas_gray focus:outline-none dark:border-0 border border-atlas_darkslate"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              placeholder="New Password"
            />
            <div
              className="absolute inset-y-0 right-0 pr-3 flex  items-center cursor-pointer"
              onClick={() => setShow(!show)}
            >
              {show ? (
                <AiOutlineEyeInvisible className="text-black dark:text-white" color="" />
              ) : (
                <AiOutlineEye className="text-black dark:text-white" color="" />
              )}
            </div>
          </div>
        </div>

        <div className="">
          <ul className="grid grid-cols-2 list-disc list-inside text-sm text-gray-400">
            <li>At least 8 characters</li>
            <li>A lowercase letter</li>
            <li>Special character !@#$-_/</li>
            <li>A number</li>
            <li>An uppercase letter</li>
          </ul>
        </div>

        <div className="pt-5">
          <label
            htmlFor="newpassword"
            className="text-atlas_black dark:text-white font-normal text-lg block"
          >
            Confirm new password <span className="text-red-500">*</span>
          </label>
          <div className="relative flex items-center">
            <input
              type={shownew ? "text" : "password"}
              name="newpassword"
              className="px-3 py-3 rounded-md w-full text-atlas_black dark:text-white bg-atlas_silver dark:bg-atlas_gray focus:outline-none dark:border-0 border border-atlas_darkslate"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
              placeholder="Confirm Password"
            />
            <div
              className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
              onClick={() => setShowNew(!shownew)}
            >
              {shownew ? (
                <AiOutlineEyeInvisible className="text-black dark:text-white" color="" />
              ) : (
                <AiOutlineEye className="text-black dark:text-white" color="" />
              )}
            </div>
          </div>
          <div className="flex justify-end">
          </div>
        </div>

        <p className="text-red-500 text-sm pt-2">{errorMessage}</p>

        <button
          className="btn py-3 mt-10">
          {loading ? <Spinner /> : "Reset"}
        </button>
      </form>
    </>
  );
};

export default Reset;
