import { useEffect, useState } from "react";
import ApiKeysForm from "./ApiKeysForm";
import { useAppContext } from "../../Context/AppContext";
import { ApiKey } from "../../util/ApikeySchema";

function ApiKeys() {
  const { sendRequest } = useAppContext();
  const [apiKey, setApiKey] = useState<ApiKey>();

  const generateApiKey = async () => {
    try {
      const response = await sendRequest("POST", "/api-keys", null);
      if (response.status === 201) {
        setApiKey(response.data.data);
      } else {
        console.log(response.data.data);
      }
    } catch (error) {
      console.error("An error occurred", error);
    }
  };

  const getApiKey = async () => {
    try {
      const response = await sendRequest("GET", "/api-keys", null);
      if (response.status === 200) {
        setApiKey(response.data.data);
      } else {
        console.log(response.data.data);
      }
    } catch (error) {
      console.error("An error occurred", error);
    }
  };

  useEffect(() => {
    getApiKey();
  }, []);

  return (
    <>
      <section className="font-manrope p-10">
        <div className="flex justify-between items-center">
          <nav className="flex flex-wrap items-center justify-between">
            <div className="block w-full flex-grow lg:flex lg:w-auto lg:items-center">
              <div>
                <button className="block px-5 py-2 border-b-2 border-atlas_dark_orange text-atlas_dark_orange">
                  ApiKeys
                </button>
              </div>
            </div>
          </nav>
        </div>

        <div className="flex flex-col font-manrope items-center justify-center bg-transparent">
          {apiKey ? (
            <div className="container mx-auto px-4">
              <ApiKeysForm Apikey={apiKey ? apiKey.live_key : ""} Secretkey={apiKey ? apiKey.live_secret : ""} />
            </div>
          ) : (
            <button
              onClick={generateApiKey}
              className="px-4 py-2 mt-10 font-manrope font-semibold bg-atlas_orange text-white rounded-lg active:scale-95 active:shadow-xl shadow hover:brightness-90"
            >
              Generate API Key
            </button>
          )}
        </div>
      </section>
    </>
  );
}

export default ApiKeys;
