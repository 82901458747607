import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import MainContent from "../../Components/KYC/MainContent";
import StepButton from "../../Components/KYC/StepButton";
import VerificationStages from "../../Components/KYC/StepsArray";
import { IUser } from "../../schema/user";

const Index = () => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState<string>(
    VerificationStages[0].id
  );
  const [completedSteps, setCompletedSteps] = useState<string[]>([]);
  const [receivedIdTypes, setReceivedIdTypes] = useState<any>(null);
  const [progress, setProgress] = useState<number>(0);
  const [user, setUser] = useState<IUser>(
    JSON.parse(localStorage.getItem("user") || "{}")
  );

  useEffect(() => {
    // console.log(user);
    if (user && user.email) {
      setUser(user);
      // check which stage the user is in
      // use the stage to determine which step to show
      const index = VerificationStages.findIndex(
        (step) => step.id === user.verification_stage
      );

      if (index + 1 > VerificationStages.length) {
        navigate("/dashboard");
        return;
      }

      const completedSteps = VerificationStages.slice(0, index + 1).map(
        (step) => step.id
      );
      console.log({ completedSteps, index } , VerificationStages);
      setCompletedSteps(completedSteps);
      setProgress((index + 1) * 50);
      if (index + 1 < VerificationStages.length) {
        setActiveStep(VerificationStages[index + 1].id);
      } else {
        // Maybe call a completion function
       console.log("User has completed all steps");
      }
    } else {
      toast.error("User not found, please login again");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleComplete = (step: string , data?: any) => {
    setUser((prev) => {
      const updatedUser = { ...prev, verification_stage: step };
      localStorage.setItem("user", JSON.stringify(updatedUser));
      return updatedUser;
    });
    if (data) {
      setReceivedIdTypes(data); // Store id_types to pass to next component
    }
  };

  return (
    <div>
      <div className="flex">
        {/* Sidebar */}
        <div className="hidden lg:block fixed top-0 left-0 h-full w-1/7 bg-atlas_dark">
          <div className="font-sans text-white pt-10 pl-7">
            <h3>Verify Your Account</h3>
            <div className="pt-20">
              {VerificationStages.map((text, index) => (
                <StepButton
                  key={index}
                  completed={completedSteps.includes(text.id)}
                  isActive={text.id === activeStep}
                  label={text.label}
                  underText={text.underText}
                  
                />
              ))}
            </div>
          </div>
        </div>

        {/* Main content */}
        <div className="flex-auto bg-atlas_black lg:ml-29 min-h-screen lg:h-auto overflow-auto">
          <div className="w-full bg-transparent h-1">
            <div
              className="bg-atlas_orange h-1.5 rounded-md"
              style={{
                width: `${progress}%`,
                transition: `width 0.5s ease-in-out`,
              }}
            ></div>
          </div>
          <MainContent activeStep={activeStep} onComplete={handleComplete} idTypes={receivedIdTypes}/>
        </div>
      </div>
    </div>
  );
};

export default Index;
