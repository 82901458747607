import { useState, useEffect } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import TableComponent from "../TableComponent";
import RightSidebar from "./RightSidebar";
import BalanceCard from "../BalanceCard";
import { useDashboardContext } from "../../Context/DashboardContext";
import { useAppContext } from "../../Context/AppContext";
import { ITransaction } from "../../schema/transaction";
import AllWallets from "../WalletsDropdown";
import formatAmount from "../../util/money";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Modal from "../Modals/Index";
import Payout from "../Payout/Payout";
import Addmoney from "../Modals/Addmoney";
import SearchBar from "../Search";
import DownloadIcon from "../../assets/DownloadIcon";
import FilterButton from "../FilterButton";
import { Status } from "../Status/Index";
// import { ChartOptions } from "chart.js";
// import gtbpic from "../../assets/gtbank.svg";
// import moniepic from "../../assets/moniepoint.jpeg";
// import sterling from "../../assets/sterlingicon.jpeg";
// import { MdOutlineShare } from "react-icons/md";
// import placeholder from "../../assets/placeholder.svg";
// import arrowLeft from "../../assets/arrow-left.svg";
// import arrowRight from "../../assets/arrow-right.svg";
// import { FiEye } from "react-icons/fi";
// import { FiEyeOff } from "react-icons/fi";
// import Ellipse from "../../assets/Ellipse.svg";
// import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";
// import BulkMenu from "../BulkMenu";
// import { useAppContext } from "../../Context/AppContext";

const columns = [
  { field: "created_at", headerName: "Date" },
  { field: "bank_name", headerName: "Bank" },
  { field: "beneficiary_name", headerName: "Beneficiary name" },
  { field: "reference", headerName: "Reference" },
  { field: "amount", headerName: "Amount" },
  { field: "status", headerName: "Status" },
  // Add more columns as needed
];

/*
const data = {
  labels: [
    "NGN",
    // "USD",
    // "KES",
    // "Others"
  ],
  datasets: [
    {
      data: [
        200,
        // 70,
        // 80,
        // 40
      ],
      backgroundColor: [
        "rgb(213,176,113)",
        // "rgb(235,87,87)",
        // "rgb(189,189,189)",
        // "rgb(47,128,237)",
      ],
      borderColor: "transparent",
      hoverOffset: 4,
    },
  ],
};

const options: ChartOptions<"doughnut"> = {
  cutout: "70%",
  radius: 45,
  plugins: {
    legend: {
      display: true,
      position: "left",
      onClick: () => {},
      labels: {
        boxWidth: 15,
        boxHeight: 15,
        padding: 6,
        font: {
          size: 12,
          weight: "normal",
        },
        color: "white",
        pointStyle: "rectRounded",
      },
    },
  },
};
*/

const DashboardPage = () => {
  ChartJS.register(ArcElement, Tooltip, Legend);
  const { sendRequest } = useAppContext();
  const { wallets, isKycVerificationComplete, user, isBusinessAccount } = useDashboardContext();
  const [isSendModalOpen, setIsSendModalOpen] = useState(false);
  const [isAddMoneyModalOpen, setIsAddMoneyModalOpen] = useState(false);
  const [contentType, setContentType] = useState("");
  const [transactions, setTransactions] = useState<ITransaction[]>([]);
  // const [viewBalance, setViewBalance] = useState(false);

  const handleOpenSendModal = () => setIsSendModalOpen(true);
  const handleOpenAddMoneyModal = () => setIsAddMoneyModalOpen(true);
  const handleCloseSendModal = () => setIsSendModalOpen(false);
  const handleCloseAddMoneyModal = () => setIsAddMoneyModalOpen(false);
  const handleTitle = (title: string) => {
    setContentType(title);
  };
  const location = useLocation();

  useEffect(() => {
    // Check if we should display the toast
    if (location.state?.showVerificationToast) {
      toast.error("Please verify your account to access this feature.");
    }
  }, [location.state]);

  const kycMessage = (() => {
    switch (user.kyc_status) {
      case "unverified":
        return "Complete your KYC to get full access.";
      case "pending":
        return "Your KYC is currently under review.";
      case "failed":
        return "KYC verification failed. Please try again.";
      default:
        return "";
    }
  })();

  function fetchTransactions(ledgerID: string) {
    sendRequest("GET", `/ledgers/${ledgerID}/transactions`, null)
      .then((res) => {
        // console.log(res);
        if (res?.data?.data?.transactions) {
          setTransactions(res.data.data.transactions);
        } else {
          setTransactions([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (wallets.length) {
      fetchTransactions(wallets[0].id);
    }
  }, [wallets]);

  return (
    <div className="lg:flex">
      <div className={`grow lg:basis-2/3 `}>
        <div className="p-5 box-border space-y-5">
          <div className="block lg:hidden" id="kyc">

            {!isKycVerificationComplete && (
              <section className="flex flex-col space-y-5 font-manrope text-white mb-10 backdrop-blur-none">
                <div className="flex flex-col space-y-5 p-4 rounded-lg border border-atlas_orange bg-atlas_gray">
                  <h4 className="font-semibold text-xl">
                    {isBusinessAccount ? "Complete Your KYB" : "KYC Status"}
                  </h4>
                  <p className="text-font-light">
                    {isBusinessAccount ? "You have limited access. Complete KYB to get full access." : kycMessage}
                  </p>
                </div>

                {isBusinessAccount ? (
                  <Link to="/kyb">
                    <button className="btn">Complete your KYB</button>
                  </Link>
                ) : user.kyc_status !== "pending" ? (
                  <Link to="/kyc">
                    <button className="btn">Complete your KYC</button>
                  </Link>
                ) : null}
              </section>
            )}
          </div>

          <div className={`${isKycVerificationComplete ? "" : "filter blur-md pointer-events-none"} lg:w-1/2`}>
            <BalanceCard ledgers={wallets} />
            <div className="flex items-center gap-4 text-nowrap mt-4">
              <button
                onClick={() => {
                  handleTitle("Addmoney");
                  handleOpenAddMoneyModal();
                }}
                className="text-white py-2 px-5 bg-atlas_gray border-2 border-solid text-sm hover:bg-atlas_orange border-white/10 rounded-lg"
              >
                Add Money
              </button>
              <button
                onClick={() => {
                  handleTitle("Send");
                  handleOpenSendModal();
                }}
                className="text-white py-2 px-5 bg-atlas_gray border-2 border-solid text-sm hover:bg-atlas_orange border-white/10 rounded-lg"
              >
                Send
              </button>
              {/* <button
                    onClick={() => {
                      handleTitle("Convert");
                      handleOpenModal();
                    }}
                    className="text-white py-2 px-5 bg-atlas_gray border-2 border-solid text-sm hover:bg-atlas_orange border-white/10 rounded-lg"
                  >
                    Convert
                  </button>
                  <button
                    className="text-white py-[2px] bg-atlas_gray border-2 border-solid text-sm hover:bg-atlas_orange border-white/10 rounded-lg"
                  >
                    <BulkMenu />
                  </button> */}
            </div>
            {/*top part ends here*/}

            {/*<div className="hidden md:block w-3/6 h-fit flex-auto px-6 py-4 bg-atlas_gray rounded-lg border-2 border-solid border-white/10">
                <h1 className="text-white text-xl font-sans">Asset distribution</h1>
                <div className="">
                  <div className="">
                    <Doughnut data={data} options={options} />
                  </div>
                </div>
              </div>*/}
          </div>

          <div className={`${isKycVerificationComplete ? "" : "filter blur-md pointer-events-none"}`}>
            <TableComponent
              header={
                <section className="flex items-center p-5 text-gray-100 rounded-t-xl justify-between bg-neutral-800 border-2 border-neutral-700">
                  <div>
                    <h2 className="text-lg cursor-default select-none">Recent Transactions</h2>
                  </div>
                  <div className="flex items-center justify-between space-x-7">
                    <div className="max-w-40 w-full">
                      {/* <SearchBar id={"search"} name={"Search"} placeholder="Search" onChange={() => {}} /> */}
                    </div>
                    {/* <div>
                      <button title="Download" className="cursor-pointer">
                        <DownloadIcon />
                      </button>
                    </div> */}
                    {/* <div>
                      <FilterButton />
                    </div> */}
                    {/* <AllWallets onChange={fetchTransactions} /> */}
                  </div>
                </section>
              }
              columns={columns}
              data={transactions?.map((t) => {
                return {
                  ...t,
                  amount: formatAmount(t.amount / 100, t.currency_code),
                  created_at: new Date(t.created_at * 1000).toLocaleString(),
                  status: Status(t.status),
                };
              })}
            />
          </div>

          {/* <div className="my-10 underline pb-2 text-white flex justify-center items-center">
              <button
                className="text-sm font-sans text-white"
                onClick={handleSeeAllClick}
              >
                See All
              </button>
            </div> */}

          {/* <div className="my-5">
              <div className="flex justify-between my-4 text-white">
                <p>Recent Transaction</p>
              </div>
              <TableComponent columns={columns} />
            </div> */}

          {/* <Modal isOpen={isModalOpen} onClose={handleCloseModal} type={contentType} /> */}
          <Modal open={isSendModalOpen} closeModal={handleCloseSendModal}>
            <Payout />
          </Modal>

          <Modal open={isAddMoneyModalOpen} closeModal={handleCloseAddMoneyModal}>
            <Addmoney onclose={handleCloseAddMoneyModal} />
          </Modal>
        </div>
      </div>

      <div className="hidden lg:basis-1/3 lg:flex border-l border-atlas_gray p-5">
        <RightSidebar />
        {/* some changes */}
      </div>
    </div>
  );
};

export default DashboardPage;
