import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { MdClose } from "react-icons/md";

interface Props {
  children: React.ReactNode;
  position?: string;
  open: boolean;
  // header: string;
  closeModal(): void;
  className?: string;
}

export default function Modal({
  children,
  open,
  closeModal,
  className,
  position,
}: // header,
Props) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="" />
        </Transition.Child>

        <div className={`fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center text-white ${position}`}>
          <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                // Find a way to make width more dynamic
                className={`transform items-center bg-atlas_gray text-left shadow-xl transition-all md:w-[500px] w-full ${className}`}
              >
                <section className="relative">
                  <div className="absolute -top-10 right-0 items-center">
                    <button
                      type="button"
                      className="text-white bg-atlas_lightgrey p-2 rounded-full hover:bg-atlas_orange"
                      onClick={closeModal}
                    >
                      <span className="sr-only">Close</span>
                      <div className="">
                      <MdClose className="text-black size-5" />
                      </div>
                    </button>
                  </div>
                </section>
                <div className="clear-both">{children}</div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
