import React, { useEffect, useState } from "react";
import { IoArrowBack } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { string } from "yup";
import { useAppContext } from "../../Context/AppContext";
import OTPPage from "../../Pages/Auth/OTPPage";
import { set } from "react-hook-form";

interface EmailProps {
  onNext: () => void;
  onPrev?: () => void;
}

const Email = ({ onNext }: EmailProps) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const { sendRequest } = useAppContext();
  const [expirationDuration, setExpirationDuration] = useState<number>(0);
  const [loading, setLoading] = useState(false);

  const resetPassword = async () => {
    try {
      setLoading(true);
      const response = await sendRequest("POST", "/forgot-password", { email });
      switch (response.status) {
        case 200:
          toast.success("Reset Password Link Has Been Sent To Your Email", {
            position: "top-right",
            theme: "dark",
            progressClassName: "!bg-atlas_orange",
          });
          // setExpirationDuration(response.data.data.expiration_duration);
          break;

        default:
          break;
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="font-sans lg:px-16 md:px-16 px-12 py-10">
        {/* <Link to="/signup">
          <button className="bg-atlas_dark-brown rounded-full p-2 ">
            <IoArrowBack size={20} />
          </button>
        </Link> */}
        <h3 className="text-4xl text-atlas_black dark:text-white pb-2">Reset password</h3>
        <p className="font-normal text-opacity-70 text-sm text-atlas_gray dark:text-atlas_darkslate">
          Input your registered Email address
        </p>
      </div>
      <form className="lg:px-16 md:px-16 px-12 font-sans">
        <div>
          <label htmlFor="email" className="text-atlas_black dark:text-white font-normal text-lg block">
            Email
            <span className="text-red-500">*</span>
          </label>
          <div>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="px-3 py-3 w-full text-atlas_black dark:text-white bg-atlas_silver dark:bg-atlas_gray focus:ring-0 rounded-md focus:outline-none dark:border-0 border border-atlas_darkslate"
              placeholder="someone@email.com"
              required
              inputMode="email"
            />
          </div>
        </div>
        <div className="">
          {/* fix onClick event handler to authenticate email before proceeding to Otp page*/}
          <button className="btn py-3 mt-10" onClick={resetPassword} type="submit" disabled={loading}>
            Continue
          </button>
        </div>
      </form>
    </div>
  );
};

export default Email;
