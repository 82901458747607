import React from "react";

interface Column {
  field: string;
  headerName: string;
  renderCell?: (row: any) => JSX.Element;
}

interface ResponsiveTableProps {
  columns: Column[];
  data?: { [key: string]: any };
  header: JSX.Element;
}

const TableComponent: React.FC<ResponsiveTableProps> = ({ header, columns, data }) => {
  return (
    <div className="">
      {header}
      <div className="overflow-x-auto">
        <table className="font-sans table-auto border-2 border-neutral-700 rounded-b-xl border-separate w-full">
          <thead className="border-b">
            <tr>
              {columns.map((column, index) => (
                <th key={index} className="text-sm font-normal text-white/50 p-3 text-left">
                  {column.headerName}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.map((row: any, rowIndex: number) => (
              <tr key={rowIndex} className="">
                {columns.map((col, i) => {
                  return (
                    <td key={i} className="text-white text-sm font-light p-4">
                      {row[col.field]}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="py-3 text-gray-200">
        <a href="#!" className="underline text-sm cursor-pointer hover:brightness-90">See all transactions</a>
      </div>
    </div>
  );
};

export default TableComponent;
