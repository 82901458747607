import { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import SplashScreen from "./splash";


const PrivateRoute = () => {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userType, setUserType] = useState<"business" | "individual" | null>(null); // Tracks if the user is individual or business
  const [isKYCVerified, setIsKYCVerified] = useState(false);
  const [isKYBVerified, setIsKYBVerified] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("user") || "null"); // Fetch user data
    const business = JSON.parse(localStorage.getItem("business") || "null"); // Fetch business data

    setIsAuthenticated(!!token); // Check authentication based on token

    if (business) {
      setUserType("business");
      setIsKYBVerified(business.kyc_verified); // Check KYB verification status
    } else if (user) {
      setUserType("individual");
      setIsKYCVerified(user.kyc_status === "verified"); // Check KYC verification status
    }

    setLoading(false);
  }, []);

  if (loading) {
    return <SplashScreen />;
  }

  const currentPath = location.pathname;

  if (
    (userType === "individual" &&
      !isKYCVerified &&
      currentPath !== "/dashboard" &&
      currentPath !== "/kyc") ||
    (userType === "business" &&
      !isKYBVerified &&
      currentPath !== "/dashboard" &&
      currentPath !== "/kyb")
  ) {
    return (
      <Navigate
      to="/dashboard"
      replace
      state={{ showVerificationToast: true }}
    />
    );
  }

  // Redirect to dashboard if KYC/KYB is verified
  // if (userType === "individual" && isKYCVerified && currentPath === "/kyc") {
  //   return <Navigate to="/dashboard" replace />;
  // }
  // if (userType === "business" && isKYBVerified && currentPath === "/kyb") {
  //   return <Navigate to="/dashboard" replace />;
  // }

  // if (
  //   (userType === "individual" && !isKYCVerified && currentPath !== "/dashboard") ||
  //   (userType === "business" && !isKYBVerified && currentPath !== "/dashboard")
  // ) {
  //   return <Navigate to="/dashboard" replace />;
  // }

  // Redirect to the correct page based on user type
  if (userType === "business" && currentPath === "/kyc") {
    return <Navigate to="/kyb" replace />;
  }
  if (userType === "individual" && currentPath === "/kyb") {
    return <Navigate to="/kyc" replace />;
  }

  // Allow access to valid routes
  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
