import ngnpic from "../../assets/ngn.svg";
import usdpic from "../../assets/usd.svg";
import gbppic from "../../assets/gbp.svg";
import kespic from "../../assets/kes.svg";
import { MdArrowDownward, MdArrowUpward } from "react-icons/md";
import { Link } from "react-router-dom";
import { useDashboardContext } from "../../Context/DashboardContext";
// import CurrencyDropdown from "../CurrencyDropdown";

const RightSidebar = () => {
  const data = [
    {
      title: "NGN",
      pic: ngnpic,
      description: "Nigerian Naira",
      symbol: "₦",
      amount: "NGN 1700/USD",
      status: "decrease",
      percentage: 4.2,
    },
    {
      title: "GBP",
      pic: gbppic,
      description: "Great British Pounds",
      symbol: "£",
      amount: "GBP 1700/USD",
      status: "increase",
      percentage: 4.2,
    },
    {
      title: "USD",
      pic: usdpic,
      description: "US Dollar",
      symbol: "$",
      amount: "USD 1/USD",
      status: "increase",
      percentage: 4.2,
    },
    {
      title: "KES",
      pic: kespic,
      description: "Kenyan Shillings",
      symbol: "KSh",
      amount: "KES 1700/USD",
      status: "decrease",
      percentage: 4.2,
    },
  ];

  const { isKycVerificationComplete, user, isBusinessAccount } = useDashboardContext();

  // Ensure showKYB is only true for unverified business accounts

  const kycMessage = (() => {
    switch (user.kyc_status) {
      case "unverified":
        return "Complete your KYC to get full access.";
      case "pending":
        return "Your KYC is currently under review.";
      case "failed":
        return "KYC verification failed. Please try again.";
      default:
        return "";
    }
  })();

  return (
    <div className="font-sans">
      {!isKycVerificationComplete && (
        <section className="flex flex-col space-y-5 font-manrope text-white mb-10 backdrop-blur-none">
          <div className="flex flex-col space-y-5 p-4 rounded-lg border border-atlas_orange bg-atlas_gray">
            <h4 className="font-semibold text-xl">
              {isBusinessAccount ? "Complete Your KYB" : "KYC Status"}
            </h4>
            <p className="text-font-light">
              {isBusinessAccount ? "You have limited access. Complete KYB to get full access." : kycMessage}
            </p>
          </div>

          {isBusinessAccount ? (
            <Link to="/kyb">
              <button className="btn">Complete your KYB</button>
            </Link>
          ) : user.kyc_status !== "pending" ? (
            <Link to="/kyc">
              <button className="btn">Complete your KYC</button>
            </Link>
          ) : null}
        </section>
      )}

      <div className={` ${isKycVerificationComplete ? "" : "filter blur-md pointer-events-none"}`}>
        <div className="">
          <div className="">
            <p className="text-white text-lg font-semibold">Supported Currencies</p>
          </div>

          {data.map((item, index) => (
            <div className="flex justify-between py-4 font-manrope" key={index}>
              <div className="flex">
                <img src={item.pic} alt="" className="w-10 h-10 rounded-full" />
                <div className="flex flex-col pl-3 text-white font-semibold text-sm ">
                  {item.title} ({item.symbol})<div className="text-white font-normal text-xs">{item.description}</div>
                </div>
              </div>

              <div>
                <div className="flex flex-col text-white font-semibold text-sm ">
                  <p className="text-center font-thin">{item.amount}</p>
                  <div
                    className={`py-1 flex self-end w-fit pl-1 pr-2 rounded-md item-center text-white font-semibold text-sm ${
                      item.status === "increase" ? "bg-atlas_green/20" : "bg-red-500/20"
                    }`}
                  >
                    <div className="flex items-center">
                      {item.status === "increase" ? (
                        <MdArrowUpward className="text-atlas_green" />
                      ) : (
                        <MdArrowDownward className="text-red-500" />
                      )}
                    </div>
                    <p
                      className={`${
                        item.status === "increase" ? "text-atlas_green" : "text-red-500"
                      } font-normal text-xs`}
                    >
                      {item.percentage}%
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="flex items-center font-manrope">
            <a href="#!" className="text-atlas_orange underline cursor-pointer hover:brightness-90">
              See all
            </a>
          </div>
          <section className="flex flex-col space-y-5 font-manrope mt-5 text-white">
            <div className="flex flex-col space-y-5 p-5 rounded-lg bg-balance-pattern bg-cover bg-no-repeat bg-neutral-600 bg-blend-overlay">
              <h4 className="font-semibold text-2xl">Trade in High Volumes</h4>
              <p className="text- font-light">
                Buy or sell assets above $50,000 with personalized service and the best rates.
              </p>
              <a href="mailto:hi@atlaxchange.com">
                <button className="self-start bg-neutral-800 bg-opacity-15 border border-neutral-200 hover:bg-opacity-75 cursor-pointer rounded-lg px-3 py-1.5">
                  Trade now
                </button>
              </a>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default RightSidebar;
