import { useFormik } from "formik";
import { useState, useEffect } from "react";
import ResendCounter from "../../Components/KYC/ResendCounter";
import OtpComponent from "../../Components/OtpComponent";
import SideImage from "../../Components/SideImage";
import Spinner from "../../Components/spinner";
import * as Yup from "yup";

interface OtpProps {
  onSubmit: (otp: string) => Promise<any>;
  resendCode: () => void;
  resendDuration: number;
  title: string;
  sideImage?: boolean;
}

export default function OTPPage({ title, onSubmit, resendCode, resendDuration, sideImage }: OtpProps) {
  const [otp, setOtp] = useState("");
  const [counter, setCounter] = useState(resendDuration);
  // const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const { handleSubmit, handleChange, errors } = useFormik({
    enableReinitialize: true,
    initialValues: { code: otp },
    validationSchema: Yup.object().shape({
      code: Yup.string().min(6, "code must be 6 digits").required("code must be 6 digits"),
    }),
    onSubmit: async (values: any) => {
      setLoading(true);
      // use sendRequest from the context
      onSubmit(values.code)
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  useEffect(() => {
    handleChange({ target: { id: "code", name: "code", value: otp } });
  }, [otp]);

  return (
    <div>
      <div className="lg:flex">
        <div className="bg-atlas_black flex flex-col flex-1 min-h-screen lg:h-auto gap-y-5">
          <div className="flex flex-1 flex-col items-center justify-center lg:justify-normal font-manrope text-white lg:p-24 px-5 pb-10">
            <section className="lg:self-start self-center">
              <h3 className="text-4xl pb-2">OTP</h3>
              <p className="font-normal text-opacity-70 text-base text-atlas_grey ">
                Enter the 6 digit Verificatin code send to your Email address
              </p>
            </section>
            <form onSubmit={handleSubmit}>
              <div className="pt-10">
                <OtpComponent onChange={setOtp} />
                <div className="text-sm flex items-center py-2 text-red-500">
                  <p className="">{errors.code ? errors.code.toString() : null}</p>
                </div>
                <div className="text-white text-sm pb-5 font-normal font-manrope flex justify-between my-5">
                  <section className="text-atlas_orange flex gap-1">
                    {counter > 0 ? (
                      <>
                        Your code expires in <ResendCounter counter={counter} setCounter={setCounter} />{" "}
                      </>
                    ) : (
                      <div className="text-red-500">expired</div>
                    )}
                  </section>

                  <button
                    type="button"
                    disabled={counter > 0}
                    className={`flex gap-x-1 text-atlas_orange active:brightness-75`}
                    onClick={resendCode}
                  >
                    Resend Code
                  </button>
                </div>
                <button disabled={loading} type="submit" className="btn py-3">
                  {loading ? <Spinner /> : "Continue"}
                </button>
              </div>
            </form>
          </div>
        </div>

        {sideImage ? (
           <div className="hidden lg:block bg-atlas_dark flex-1">
           <div className="flex justify-center items-center h-full">
             <SideImage />
           </div>
         </div>
        ) : (
          null
        )}
      </div>
    </div>
  );
}
