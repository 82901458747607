import { useEffect, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { FiEye } from "react-icons/fi";
import { FiEyeOff } from "react-icons/fi";
import plusIcon from "../assets/+.svg";
import Modal from "./Modals/Modal";
import formatAmount from "../util/money";
import { ILedger } from "../schema/ledger";

interface BalanceCardsProps {
  ledgers: ILedger[];
}

function BalanceCard({ ledgers }: BalanceCardsProps) {
  const [wallets, setWallets] = useState<ILedger[]>([]);
  const [viewBalance, setViewBalance] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [animationDirection, setAnimationDirection] = useState(" ");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contentType, setContentType] = useState("");

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const openCreateWalletModal = () => {
    setContentType("CreateWallet");
    handleOpenModal();
  };

  const prevWallet = () => {
    if (!wallets || wallets.length <= 1) return;

    setAnimationDirection("slide-in-right");
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex === 0 ? wallets.length - 1 : prevIndex - 1));
    }, 200);
  };

  const nextWallet = () => {
    if (!wallets || wallets.length <= 1) return;

    setAnimationDirection("slide-in-left");
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex === wallets.length - 1 ? 0 : prevIndex + 1));
    }, 200);
  };

  const toggleViewBalance = () => {
    setViewBalance(!viewBalance);
  };

  useEffect(() => {
    if (ledgers) {
      setWallets((prev) => {
        return [
          ...ledgers,
          {
            currency_code: "",
            id: "",
            status: "",
            balance: 0,
            description: "",
            bank_accounts: [],
          },
        ];
      });
    }
  }, [ledgers]);

  return (
    <div className="flex flex-col h-34">
      <div
        className={`${animationDirection} relative font-manrope h-full bg-balance-pattern bg-cover bg-no-repeat bg-neutral-600 bg-blend-overlay overflow-hidden rounded-lg ${wallets.length === 0 ? "" : "justify-between flex-col"} `}
      >
        {wallets.length !== 0 ? (
          wallets[currentIndex].currency_code === "" ? (
            <div className="relative h-full">
              <div onClick={openCreateWalletModal} className="cursor-pointer flex items-center justify-center text-white h-full">
                <p className="absolute top-1 left-1 text-white font-manrope py-2 px-3">Add Wallet</p>
                <img src={plusIcon} alt="add" />
              </div>
            </div>
          ) : (
            <div className="px-7 py-3">
              <div className="flex items-start justify-between text-white ">
                <p className="pb-2 text-xl font-sans">Balance ({wallets[currentIndex]?.currency_code}) </p>
                <button className="" onClick={toggleViewBalance}>
                  {viewBalance ? (
                    <FiEyeOff className="size-7 cursor-pointer hover:bg-opacity-30 hover:bg-neutral-600 p-1 rounded-full" />
                  ) : (
                    <FiEye className="size-7 cursor-pointer hover:bg-opacity-30 hover:bg-neutral-600 p-1 rounded-full" />
                  )}
                </button>
              </div>
              <div className="flex text-white font-manrope font-semibold text-3xl items-center justify-start">
                {viewBalance ? (
                  <p className="">********</p>
                ) : (
                  /*<p className="flex text-white font-manrope font-bold text-3xl lg:text-4xl items-center justify-start px-3 py-7">*/
                  <p className="">
                    {/*wallets[currentIndex].Currency.Symbol*/}
                    {formatAmount(wallets[currentIndex]?.balance / 100, wallets[currentIndex].currency_code)}
                  </p>
                )}
              </div>
            </div>
          )
        ) : (
          <div className="relative h-full">
            <div onClick={openCreateWalletModal} className="cursor-pointer flex items-center justify-center text-white h-full">
              <p className="absolute top-1 left-1 text-white font-manrope py-2 px-3">Add Wallet</p>
              <img src={plusIcon} alt="add" />
            </div>
          </div>
        )}
        {/* Navigation Arrows */}
        <button
          onClick={prevWallet}
          className={`absolute top-1/2 left-1 transform bg-transparent text-white -translate-y-1/2 ${wallets.length <= 1 ? "hidden" : ""}`}
        >
          <IoIosArrowBack className="size-6 cursor-pointer hover:bg-opacity-30 hover:bg-neutral-600 p-1 rounded-full" />
        </button>
        <button
          onClick={nextWallet}
          className={`absolute top-1/2 right-1 transform  bg-transparent text-white -translate-y-1/2 ${wallets.length <= 1 ? "hidden" : ""}`}
        >
          <IoIosArrowForward className="size-6 cursor-pointer hover:bg-opacity-30 hover:bg-neutral-600 p-1 rounded-full" />
        </button>
      </div>
      {/* <div className="flex items-center justify-between space-x-2 px-4">
        <span className="p-[2px] rounded-full w-1/3 brightness-50 bg-atlas_orange"></span>
        <span className="p-[2px] rounded-full w-1/3 bg-atlas_orange"></span>
        <span className="p-[2px] rounded-full w-1/3 brightness-50 bg-atlas_orange"></span>
      </div> */}
      <div className="flex items-center justify-between space-x-5 px-4 py-1">
        {wallets.map((_, index) => (
          <div
            key={index}
            className={`p-0.5 rounded-full w-1/3 bg-atlas_orange ${index === currentIndex ? "brightness-100" : "brightness-50"}`}
          ></div>
        ))}
      </div>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal} type={contentType} />
    </div>
  );
}

export default BalanceCard;
