import { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { FiAlertCircle } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Spinner from "../../Components/spinner";
import { useFormik } from "formik";
import * as Yup from "yup";
import SideImage from "../../Components/SideImage";
import Logo from "../../Components/Logo";
import YupPassword from "yup-password";
import { useAppContext } from "../../Context/AppContext";
import OTPPage from "./OTPPage";
// import { FaRegCheckCircle } from "react-icons/fa";
// import logo from "../../assets/Logo.svg";

YupPassword(Yup);

const SignupPage = () => {
  const navigate = useNavigate();
  const { sendRequest } = useAppContext();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [email, setEmail] = useState<string | null>();
  const [stage, setStage] = useState<"signup" | "otp">("signup");
  const [expirationDuration, setExpirationDuration] = useState<number>(0);

  const SignupSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is required"),
    phone: Yup.string()
      .matches(/^\+\d+$/, "Phone number must start with '+' and contain only digits.")
      .required("Phone number is required"),
    password: Yup.string()
      .min(8, "Must be at least 8 characters long")
      .minLowercase(1, "Must contain at least one lowercase letter")
      .minUppercase(1, "Must contain at least one uppercase letter")
      .minNumbers(1, "Must contain at least one number")
      .minSymbols(1, "Must contain at least one special character")
      .required("Password is required"),
    confirm_password: Yup.string()
      .min(8, "Must be at least 8 characters long")
      .required("Password confirmation is required")
      .oneOf([Yup.ref("password")], "Passwords do not match"),
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    above_18: Yup.bool()
      .isTrue("You must be above 18 years of age")
      .required("You must be above 18 years of age"),
    t_and_c: Yup.bool()
      .isTrue("You must agree to terms and conditions")
      .required("You must agree to terms and conditions"),
  });

  const { getFieldProps, handleSubmit, errors, touched } = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      phone: "",
      password: "",
      confirm_password: "",
      first_name: "",
      last_name: "",
      above_18: false,
      t_and_c: false,
      customer_type: "individual",
    },
    validationSchema: SignupSchema,
    onSubmit: async (values: any) => {
      setLoading(true);
      // use the sendRequest function from appContext
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/signup`, values)
        .then((response) => {
          // NOTE: any change here should be done in the business regirstration page as well, until
          // we refactor it to use the same logic
          if (response.status == 201) {
            setEmail(values.email);
            setExpirationDuration(response.data.data.expiration_duration);
            // const message = "Singup successful, we will contact you when your dashboard is ready";
            const message =
              "Singup successful, please proceed to complete your sign up";
            toast.info(message, {
              position: "top-right",
              theme: "dark",
              progressClassName: "!bg-atlas_orange",
            });
            // localStorage.setItem("user", JSON.stringify(response.data.data));
            // navigate("/otp");
            setStage("otp");
          }
        })
        .catch((error) => {
          console.log(error);
          const message = error.response?.data?.message
            ? error.response.data.message
            : "something went wrong";
          toast.error(message, {
            position: "top-right",
            theme: "dark",
            progressClassName: "!bg-atlas_orange",
          });
          // const errMsg = error.response.data.errors;
          // toast.error(errMsg ? errMsg : "An error occured, please try again", {
          //   position: "top-center",
          //   theme: "dark",
          //   progressClassName: "!bg-atlas_orange",
          // });
        })
        .finally(() => {
          setLoading(false);
        });
      // Proceed with updating the password or other actions
    },
  });

  function submitOTP(otp: string) {
    if (otp.length < 6) {
      toast.error("Please enter the complete code");
      return Promise.reject("please enter the complete code");
    }
    return sendRequest("POST", "/verify-email", { email, code: otp })
      .then(
        (response: {
          data: {
            message: string;
            data: {
              access_token: string;
              refresh_token: string;
              user: any;
            };
          };
        }) => {
          toast.success("Verification Complete");
          navigate("/login");
        }
      )
      .catch((err: any) => {
        let errMsg = "An error occurred, please try again";
        if (err.response && err.response.data && err.response.data.message) {
          errMsg = err.response.data.message;
        } else if (
          err.response &&
          err.response.data &&
          err.response.data.errors
        ) {
          errMsg = err.response.data.errors;
        }
        toast.error(errMsg);
        // setError(errMsg)
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function resendCode() {
    sendRequest("POST", "/resend-code", { email })
      .then((response: any) => {
        console.log({ response });
        toast.success("Code sent successfully");
      })
      .catch((err: any) => {
        let errMsg = "An error occurred, please try again";
        if (err.response && err.response.data && err.response.data.errors) {
          // todo: check if errors is an object
          for (const [key, value] of Object.entries(err.response.data.errors)) {
            // console.log(`${key}: ${value}`);
            toast.error(`${key}: ${value}`);
          }
          // errMsg = `${err.response.data.errors}`;
        } else {
          toast.error(errMsg);
        }
      });
  }

  return stage === "otp" ? (
    <OTPPage
      title="Enter the 6 digit code verification sent to your email address"
      onSubmit={submitOTP}
      resendCode={resendCode}
      resendDuration={expirationDuration}
    />
  ) : (
    <div>
      <div className="lg:flex font-manrope">
        <div className="bg-white dark:bg-atlas_black flex flex-col flex-1 min-h-screen lg:h-auto py-5 lg:py-10 gap-y-5">
          <div className="md:mx-16 mx-6 items-center flex justify-between">
            <Logo />
            <div>
              <Link
                to="/signup/business"
                className="font-normal text-sm cursor-pointer text-atlas_orange transition-all ease-in duration-150 hover:brightness-90 hover:underline">
                Register as business
              </Link>
            </div>
          </div>

          <div className="flex flex-1 flex-col">
            <div className="font-manrope text-white lg:px-16 md:px-16 px-12 pb-10">
              <h3 className="text-4xl text-atlas_black dark:text-white pb-2">
                Create Account
              </h3>
              <p className="font-normal  text-opacity-70 text-sm text-atlas_gray dark:text-atlas_darkslate">
                Enter the following information correctly to create your Atlax
                account
              </p>
            </div>

            <form
              className="lg:px-16 md:px-16 px-12 font-manrope space-y-6"
              onSubmit={handleSubmit}>
              <div>
                <label
                  htmlFor="first_name"
                  className="text-atlas_black dark:text-white font-normal text-lg block">
                  Legal First name
                </label>
                <div className="relative flex items-center">
                  <input
                    type="text"
                    required
                    className={`${touched.first_name && errors.first_name
                      ? "border-red-600"
                      : "border-none"
                      } px-3 py-3 w-full text-atlas_black dark:text-white bg-atlas_silver dark:bg-atlas_gray focus:ring-0 rounded-md focus:outline-none border border-atlas_darkslate`}
                    {...getFieldProps("first_name")}
                    placeholder="First name"
                    inputMode="text"
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer">
                    {touched.first_name && errors.first_name ? (
                      <FiAlertCircle className="text-red-600" />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {touched.first_name && errors.first_name ? (
                  <div className="flex justify-start text-sm pt-2 text-red-500">
                    {errors.first_name.toString()}
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div>
                <label
                  htmlFor="last_name"
                  className="text-atlas_black dark:text-white font-normal text-lg block">
                  Legal Last name
                </label>
                <div className="relative flex items-center">
                  <input
                    type="text"
                    {...getFieldProps("last_name")}
                    className={`${touched.last_name && errors.last_name
                      ? "border-red-600"
                      : "border-none"
                      } px-3 py-3 w-full text-atlas_black dark:text-white bg-atlas_silver dark:bg-atlas_gray focus:ring-0 rounded-md focus:outline-none border border-atlas_darkslate`}
                    required
                    inputMode="text"
                    placeholder="Last name"
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer">
                    {touched.last_name && errors.last_name ? (
                      <FiAlertCircle className="text-red-600" />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {touched.last_name && errors.last_name ? (
                  <div className="flex justify-start text-sm pt2 text-red-500">
                    {errors.last_name.toString()}
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div>
                <label
                  htmlFor="email"
                  className="text-atlas_black dark:text-white font-normal text-lg block">
                  Email
                </label>
                <div className="relative flex items-center">
                  <input
                    type="email"
                    {...getFieldProps("email")}
                    className={`${touched.email && errors.email
                      ? "border-red-600"
                      : "border-none"
                      } px-3 py-3 w-full text-atlas_black dark:text-white bg-atlas_silver dark:bg-atlas_gray focus:ring-0 rounded-md focus:outline-none border border-atlas_darkslate`}
                    required
                    inputMode="email"
                    placeholder="someone@email.com"
                  />
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer">
                    {touched.email && errors.email ? (
                      <FiAlertCircle className="text-red-600" />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {touched.email && errors.email ? (
                  <div className="flex justify-start text-sm pt-2 text-red-500">
                    {errors.email.toString()}
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div>
                <label
                  htmlFor="phone"
                  className="text-atlas_black dark:text-white font-normal text-lg block">
                  Phone Number (Add country code e.g +234)
                </label>
                <div className="flex items-center space-x-2 bg-atlas_silver dark:bg-atlas_gray rounded-md">
                  <div className="relative flex w-full items-center">
                    <input
                      type="text"
                      className={`${touched.phone && errors.phone
                        ? "border-red-600"
                        : "border-none"
                        } px-3 py-3 w-full text-atlas_black dark:text-white bg-atlas_silver dark:bg-atlas_gray rounded-md  border focus:ring-0 focus:outline-none`}
                      {...getFieldProps("phone")}
                      required
                      placeholder="+234 800 000 000"
                      inputMode="tel"
                    />
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer">
                      {touched.phone && errors.phone ? (
                        <FiAlertCircle className="text-red-600" />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                {touched.phone && errors.phone ? (
                  <div className="flex justify-start text-sm pt-2 text-red-500">
                    {errors.phone.toString()}
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="text-atlas_black dark:text-white text-lg font-normal block">
                  Password
                </label>
                <div className="relative flex items-center">
                  <input
                    type={showPassword ? "text" : "password"}
                    {...getFieldProps("password")}
                    className={`${touched.password && errors.password
                      ? "border-red-600"
                      : "border-none"
                      } px-3 py-3 rounded-md w-full text-atlas_black dark:text-white bg-atlas_silver dark:bg-atlas_gray focus:outline-none border border-atlas_darkslate`}
                    required
                    inputMode="text"
                    placeholder="Password"
                  />
                  <div
                    className="absolute inset-y-0 right-0 pr-3 flex space-x-2 items-center cursor-pointer"
                    onClick={() => setShowPassword(!showPassword)}>
                    {touched.password && errors.password ? (
                      <FiAlertCircle className="text-red-600" />
                    ) : (
                      ""
                    )}

                    {showPassword ? (
                      <AiOutlineEyeInvisible
                        className="text-black dark:text-white"
                        color=""
                      />
                    ) : (
                      <AiOutlineEye
                        className="text-black dark:text-white"
                        color=""
                      />
                    )}
                  </div>
                </div>
                {touched.password && errors.password ? (
                  <div className="flex justify-start text-sm pt-2 text-red-500">
                    {errors.password.toString()}
                  </div>
                ) : (
                  ""
                )}
                {/* {touched.password && errors.password ?
                  <div className="pt-2">
                    <ul className="grid grid-cols-2 list-disc list-inside text-xs text-green-500">
                      <li>At least 8 characters</li>
                      <li>A lowercase letter</li>
                      <li>Special character !@#$-_/</li>
                      <li>A number</li>
                      <li>An uppercase letter</li>
                    </ul>
                  </div> : ""} */}
              </div>

              <div>
                <label
                  htmlFor="Confirmpassword"
                  className="text-atlas_black dark:text-white text-lg font-normal block">
                  Confirm Password
                </label>
                <div className="relative flex items-center">
                  <input
                    type={showConfirm ? "text" : "password"}
                    {...getFieldProps("confirm_password")}
                    className={`${touched.password && errors.confirm_password
                      ? "border-red-600"
                      : "border-none"
                      } px-3 py-3 w-full rounded-md text-atlas_black dark:text-white bg-atlas_silver dark:bg-atlas_gray focus:outline-none border border-atlas_darkslate focus:border focus:border-atlas_orange`}
                    required
                    inputMode="text"
                    placeholder="Confirm Password"
                  />
                  <div
                    className="absolute inset-y-0 right-0 pr-3 flex space-x-2 items-center cursor-pointer"
                    onClick={() => setShowConfirm(!showConfirm)}>
                    {touched.confirm_password && errors.confirm_password ? (
                      <FiAlertCircle className="text-red-600" />
                    ) : (
                      ""
                    )}

                    {showConfirm ? (
                      <AiOutlineEyeInvisible
                        className="text-black dark:text-white"
                        color=""
                      />
                    ) : (
                      <AiOutlineEye
                        className="text-black dark:text-white"
                        color=""
                      />
                    )}
                  </div>
                </div>
                {touched.confirm_password && errors.confirm_password ? (
                  <div className="flex justify-start pt-2 text-sm text-red-500">
                    {errors.confirm_password.toString()}
                  </div>
                ) : (
                  ""
                )}
              </div>

              {errorMessage && (
                <div className="flex justify-start py-2 text-red-500">
                  {errorMessage}
                </div>
              )}
              {/* --------- form input boxes end. -----------  */}

              <section className="flex flex-col text-slate-400 text-xs lg:text-sm font-light ">
                <div className="flex items-center">
                  <label
                    className="relative flex cursor-pointer items-center rounded-full p-3"
                    data-ripple-dark="true">
                    <input
                      type="checkbox"
                      id="above_18"
                      {...getFieldProps("above_18")}
                      className="peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-atlas_orange shadow hover:shadow-md transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-slate-400 before:opacity-0 before:transition-opacity checked:border-atlas_orange checked:bg-atlas_orange checked:before:bg-slate-400 hover:before:opacity-10"
                    />
                    <span className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-3.5 w-3.5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        stroke="currentColor"
                        strokeWidth="1">
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"></path>
                      </svg>
                    </span>
                  </label>
                  <label htmlFor="above_18">I am 18 years of age or older</label>
                </div>
                {touched.above_18 && errors.above_18 ? (
                  <div className="ml-2 text-red-500">
                    {errors.above_18.toString()}
                  </div>
                ) : (
                  ""
                )}

                {/* between the two checkbox elements */}

                <div className="flex items-center">
                  <label
                    className="relative flex cursor-pointer items-center rounded-full p-3"
                    data-ripple-dark="true">
                    <input
                      type="checkbox"
                      id="t_and_c"
                      {...getFieldProps("t_and_c")}
                      className="peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-atlas_orange shadow hover:shadow-md transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-slate-400 before:opacity-0 before:transition-opacity checked:border-atlas_orange checked:bg-atlas_orange checked:before:bg-slate-400 hover:before:opacity-10"
                    />
                    <span className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-3.5 w-3.5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        stroke="currentColor"
                        strokeWidth="1">
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"></path>
                      </svg>
                    </span>
                  </label>
                  <label className="block" htmlFor="t_and_c">
                    I agree to the user agreement and I have read the privacy
                    policy
                  </label>
                </div>
                {touched.t_and_c && errors.t_and_c ? (
                  <div className="ml-2 text-red-500">
                    {errors.t_and_c.toString()}
                  </div>
                ) : (
                  ""
                )}
              </section>

              <button
                type="submit"
                disabled={loading}
                className="btn mt-4 py-3 hover:brightness-90 bg-atlas_orange">
                {loading ? <Spinner /> : "Sign Up"}
              </button>
              <div className="flex space-x-2 items-center place-content-center italic text-xs md:text-sm">
                <p className="text-atlas_gray dark:text-atlas_grey cursor-default opacity-85">
                  Do you already have an Atlax account?
                </p>
                <Link
                  to="/login"
                  className="font-normal cursor-pointer text-atlas_orange transition-all ease-in duration-150 hover:brightness-90 hover:underline">
                  Sign In
                </Link>
              </div>
            </form>
          </div>
        </div>

        <div className="hidden lg:block bg-atlas_dark flex-1">
          <div className="flex justify-center items-start h-full">
            <SideImage />
            {/* <img src={LoginImage} alt="patterns" className=" h-full " /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupPage;
