export function Status(status: string) {
  // const color 
    switch (status) {
      case "pending":
        return (
          <span className="capitalize font-semibold p-2 rounded-[10px] text-xs text-atlas_orange bg-atlas_gold">
            {status}
          </span>
        );
        case "reversed":
        return (
          <span className="capitalize font-semibold p-2 rounded-[10px] text-xs text-white bg-atlas_gold">
            {status}
          </span>
        );
      case "failed":
        return (
          <span className="capitalize font-semibold p-2 rounded-[10px] text-xs text-red-500 bg-atlas_gold">
            {status}
          </span>
        );
      default:
        return (
          <span className="capitalize font-semibold p-2 rounded-[10px] text-xs text-atlas_gold bg-white">
            {status}
          </span>
        );
    }
  }
  