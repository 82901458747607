import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import CustomSelect from "./Customselect";
import CustomState from "./CustomStates";
import logo from "../../assets/Logo.svg";
import emoji from "../../assets/icon _Happy and Smile_.svg";
import countryList from "react-select-country-list";
import { getStates } from "country-state-picker";
import { KybFormContext } from "../../Context/KybFormContext";
import Button from "../button";

interface Props {
  onComplete: (step: string) => void;
}

const BusinessInfo = ({ onComplete }: Props) => {
  const { formData, updateField } = useContext(KybFormContext);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageState, setErrorMessageState] = useState("");
  const [selectedNationality, setSelectedNationality] = useState("");
  const [selectedState, setSelectedState] = useState<string>("");
  const [states, setStates] = useState<string[]>([]);

  const handleStateSelect = (states: string) => {
    setErrorMessageState("");
    setSelectedState(states);
    updateField("state", states);
  };

  useEffect(() => {
    // this will run everytime selected nationality is changed
    setStates(getStates(countryList().getValue(selectedNationality)?.toLowerCase()) || []);
  }, [selectedNationality]);


  const handleSelect = (country: string) => {
    errorMessage && setErrorMessage("");

    // Reset state when country changes
    if (country !== selectedNationality) {
      setSelectedState(""); // Clear the previously selected state
      setErrorMessageState(""); // Clear state error message
    }

    setSelectedNationality(country);
  };

  return (
    <div className="bg-white dark:bg-atlas_black text-black dark:text-white font-manrope ">
      <div>
        <div className="flex lg:justify-end justify-between px-10 pt-6 ">
          <Link to="/" className="flex items-center justify-between lg:hidden">
            <img className="w-12 h-12 p-2 cursor-pointer fill-atlas_orange" src={logo} alt="logo" />
          </Link>
          <p className="flex items-center text-white text-sm">
            We really need this part &nbsp; <img src={emoji} alt="emoji" />{" "}
          </p>
        </div>
        <div className="lg:w-4/6 w-full py-14 lg:pl-20 px-10">
          <h3 className="text-2xl text-white font-semibold pt-8 pb-2">Business Information</h3>
          <Formik
            initialValues={{
              businessName: formData.businessName,
              address: formData.address,
              postcode: formData.postalCode,
              website: formData.website,
              supportEmail: formData.supportEmail,
            }}
            validationSchema={Yup.object({
              businessName: Yup.string().required("Required fields are missing"),
              address: Yup.string().required("Required fields are missing"),
              postcode: Yup.string().required("Required fields are missing"),
              website: Yup.string()
                .matches(
                  /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                  "Enter a valid url!"
                )
                .required("Required fields are missing"),
              supportEmail: Yup.string().email("Invalid email").required("Required fields are missing"),
            })}
            onSubmit={(values) => {
              if (!selectedNationality) {
                console.log("selectedNationality", !selectedNationality);
                setErrorMessage("Required fields are missing");
                return;
              }
              if (!selectedState) {
                console.log("selectedNationality", !selectedNationality);
                setErrorMessageState("Required fields are missing");
                return;
              }

              const combinedAddress = `${values.address} ${selectedNationality}`.trim();
              updateField("address", combinedAddress);
              // Update context with formik values on submit
              Object.entries(values).forEach(([key, value]) => {
                // Map Formik field names to context field names if necessary
                if (key === "postcode") {
                  updateField("postalCode", value);
                } else {
                  updateField(key, value);
                }
              });
              onComplete && onComplete("business_info");
            }}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit} className="pt-10 font-sans">
                <div className="grid ">
                  <div className="mb-10">
                    <label htmlFor="business_name" className="text-white font-normal text-sm mb-2 block">
                      Business name <span className="text-red-500">*</span>
                    </label>
                    <Field
                      type="text"
                      name="businessName"
                      className="px-2 py-2 w-full text-white bg-atlas_silver dark:bg-atlas_darker border-opacity-20 border focus:ring-0 border-atlas_slate dark:border-atlas_darkgray rounded-md focus:outline-none focus:border-atlas_orange"
                      placeholder="Business Name"
                    />
                    <ErrorMessage name="businessName" component="div" className="error text-red-500" />
                  </div>

                  <div className="mb-10">
                    <label htmlFor="country" className="text-white font-normal text-sm mb-2 block">
                      Country of Residence <span className="text-red-500">*</span>
                    </label>
                    <CustomSelect onSelect={handleSelect} />
                    {errorMessage && <div className="text-red-500 text-sm mb-2">{errorMessage}</div>}
                  </div>
                </div>

                <div className="mb-10">
                  <label htmlFor="address" className="text-white font-normal text-sm mb-2 block">
                    Business Address <span className="text-red-500">*</span>
                  </label>
                  <Field
                    type="text"
                    name="address"
                    className="px-2 py-2 w-full text-white bg-atlas_silver dark:bg-atlas_darker border-opacity-20 border focus:ring-0 border-atlas_slate dark:border-atlas_darkgray rounded-md focus:outline-none focus:border-atlas_orange"
                    placeholder="Business Address"
                  />
                  <ErrorMessage name="address" component="div" className="error text-red-500" />
                </div>

                <div className="relative mb-10">
                  <div>
                    <label htmlFor="state" className="text-white font-normal text-sm mb-2 block">
                      Select State <span className="text-red-500">*</span>
                    </label>
                    <CustomState onSelect={handleStateSelect} items={states || []} selectedValue={selectedState} />
                  </div>
                  {errorMessageState && <div className="text-red-500 text-sm mb-2">{errorMessageState}</div>}
                </div>

                <div className="mb-10">
                  <label htmlFor="postcode" className="text-white font-normal text-sm mb-2 block">
                    Postal Code <span className="text-red-500">*</span>
                  </label>
                  <Field
                    type="text"
                    name="postcode"
                    className="px-2 py-2 w-full text-white bg-atlas_silver dark:bg-atlas_darker border-opacity-20 border focus:ring-0 border-atlas_slate dark:border-atlas_darkgray rounded-md focus:outline-none focus:border-atlas_orange"
                    placeholder="Postal Code"
                  />
                  <ErrorMessage name="postcode" component="div" className="error text-red-500" />
                </div>

                <div className="mb-10">
                  <label htmlFor="website" className="text-white font-normal text-sm mb-2 block">
                    Business Website <span className="text-red-500">*</span>
                  </label>
                  <Field
                    type="text"
                    name="website"
                    className="px-2 py-2 w-full text-white bg-atlas_silver dark:bg-atlas_darker border-opacity-20 border focus:ring-0 border-atlas_slate dark:border-atlas_darkgray rounded-md focus:outline-none focus:border-atlas_orange"
                    placeholder="https://businesswebsite.com"
                  />
                  <ErrorMessage name="website" component="div" className="error text-red-500" />
                </div>

                <div className="mb-10">
                  <label htmlFor="supportEmail" className="text-white font-normal text-sm mb-2 block">
                    Support Email <span className="text-red-500">*</span>
                  </label>
                  <Field
                    type="email"
                    name="supportEmail"
                    className="px-2 py-2 w-full text-white bg-atlas_silver dark:bg-atlas_darker border-opacity-20 border focus:ring-0 border-atlas_slate dark:border-atlas_darkgray rounded-md focus:outline-none focus:border-atlas_orange"
                    placeholder="support@email.com"
                  />
                  <ErrorMessage name="supportEmail" component="div" className="error text-red-500" />
                </div>

                <div className="flex justify-end">
                  <Button label="Continue" className="py-2 px-9" />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default BusinessInfo;
